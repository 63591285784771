<script setup lang="ts">
import { storeToRefs } from 'pinia'

import { vClickOutside } from '~/directives/ClickOutside'

import CurrencySymbol from '~/components/shared/currency/symbol.vue'
import { Sm600Orange } from '~/components/typography'

import { useSegmentTrack } from '~/composables/Segment/useSegmentTrack'
import { useCurrencySetter } from '~/composables/useCurrencySetter'

import { useMiscCurrencyStore } from '~/stores/miscCurrency'
import { useMiscResizeStore } from '~/stores/miscResize'
import { useTagStore } from '~/stores/tag'
import { useUserAgencyStore } from '~/stores/userAgency'
import { useUserBandStore } from '~/stores/userBand'

import { trackEvent } from '~/helpers/LegacyTrackEvent'

import type Tag from '~/entities/tag'
import type { Currency } from '~/types/currency'

type Props = {
  contextMenuMoveBreakpoint?: number
}

const props = withDefaults(defineProps<Props>(), {
  contextMenuMoveBreakpoint: 0,
})

const route = useRoute()
const segmentTrack = useSegmentTrack()
const { setTargetCurrency } = useCurrencySetter()

const expanded = ref(false)

const { SCREEN_WIDTH } = storeToRefs(useMiscResizeStore())
const { ALL_CURRENCIES, targetCurrency: TARGET_CURRENCY } = storeToRefs(
  useMiscCurrencyStore(),
)
const { GET_TAGS_FROM_IDS } = useTagStore()
const { BAND_DATA } = storeToRefs(useUserBandStore())
const { tags: USER_AGENCY_TAGS } = storeToRefs(useUserAgencyStore())

const hasContextMenuMoveClass = computed<boolean>(
  () => props.contextMenuMoveBreakpoint <= SCREEN_WIDTH.value,
)

function toggleContextMenu(): void {
  expanded.value = !expanded.value
  trackEvent(
    {
      category: 'Currency swapper',
      action: expanded.value ? 'Open' : 'Close',
    },
    route,
  )
}

function closeContextMenu(): void {
  if (expanded.value) {
    trackEvent(
      {
        category: 'Currency swapper',
        action: 'Close',
      },
      route,
    )
  }

  expanded.value = false
}

function getCountriesFromTags(tags: Tag[]): string[] {
  const tagIds = tags.map((tag) => tag.id)
  return GET_TAGS_FROM_IDS(tagIds).map((e) => e.name)
}

function getCountriesList(): string[] | undefined {
  let countriesList = [] as string[] | undefined

  const agencyTags = USER_AGENCY_TAGS.value?.identity?.country as Tag[] | []

  const bandTags = BAND_DATA.value?.tags?.identity?.country as Tag[] | []

  if (agencyTags?.length > 0) countriesList = getCountriesFromTags(agencyTags)
  else if (bandTags?.length > 0) countriesList = getCountriesFromTags(bandTags)
  else countriesList = undefined

  return countriesList
}

function updateCurrency(currencyCode: Currency): void {
  closeContextMenu()
  setTargetCurrency(currencyCode)

  // Don't send the event for unlogged users on the landing page
  if (route.name?.toString().startsWith('index__')) return

  const countriesList: string[] | undefined = getCountriesList()

  segmentTrack('Billing - Currency Type Selected', {
    id_band: BAND_DATA.value?.id || 0,
    page_title: route.name?.toString(),
    currency_selected: currencyCode,
    countries_list: countriesList,
  })
}
</script>

<template>
  <div
    v-click-outside="closeContextMenu"
    class="tw-relative tw-z-10 tw-flex tw-cursor-pointer tw-items-center tw-justify-start tw-text-orange-500"
    @click="toggleContextMenu"
  >
    <Sm600Orange class="tw-mr-1 tw-block">
      <CurrencySymbol />
      <span>{{ TARGET_CURRENCY }}</span>
    </Sm600Orange>
    <i class="fas fa-chevron-down chevron" :class="expanded" />
    <transition name="fade">
      <div
        v-if="expanded"
        class="contextMenu"
        :class="{ hasContextMenuMoveClass }"
      >
        <div v-for="currencyCode in ALL_CURRENCIES" :key="currencyCode">
          <div
            class="elemHolder"
            :class="{ selected: currencyCode === TARGET_CURRENCY }"
            @click.stop="updateCurrency(currencyCode)"
          >
            <i class="fas fa-check tw-text-base" />
            <div
              class="tw-text-left tw-text-base"
              :datat-test-id="`walletPackCardCurrency${currencyCode}`"
            >
              <CurrencySymbol :target-currency="currencyCode" />
              <span>{{ currencyCode }}</span>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
.chevron {
  @apply tw-text-xs tw-transition-transform tw-duration-150 tw-ease-in-out;
}

.contextMenu {
  @apply tw-absolute tw-w-40 tw-overflow-y-auto tw-rounded-xs tw-bg-white tw-py-3 tw-text-black tw-shadow-hoverDropdown;

  top: calc(100% + 8px);
  left: calc(50% - (160px / 2));
  max-height: 320px;

  &.hasContextMenuMoveClass {
    left: -8px;
  }
}

.elemHolder {
  @apply tw-grid tw-items-center tw-gap-2 tw-px-3 tw-py-2 tw-font-medium tw-transition-colors tw-duration-150 tw-ease-in-out;

  grid-template-columns: 16px 1fr;

  &:hover {
    @apply tw-bg-gray-200;
  }

  &:not(.selected) {
    > i {
      @apply tw-opacity-0;
    }
  }

  &.selected {
    @apply tw-text-orange-500;
  }
}
</style>
