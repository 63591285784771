import { useCurrencySetter } from '~/composables/useCurrencySetter'

import { useMiscCurrencyStore } from '~/stores/miscCurrency'

import type { CurrencyLookUpTable } from '~/stores/miscCurrency'
import type { Directive } from 'vue'

export function currencyConvertHelper(
  value: number,
  noRounding = false,
): number {
  // FIXME: composables should be moved outside of the directive
  const miscCurrencyStore = useMiscCurrencyStore()

  if (
    miscCurrencyStore.IS_USING_CONVERSION &&
    miscCurrencyStore.HAS_LOOK_UP_TABLE
  ) {
    const { targetCurrency } = miscCurrencyStore.$state
    const currencyLookupTable: CurrencyLookUpTable =
      miscCurrencyStore.ADJUSTED_LOOKUP_TABLE

    const ouput = value * currencyLookupTable[targetCurrency]
    return noRounding ? ouput : Math.round(ouput)
  } else {
    return noRounding ? value : Math.round(value)
  }
}

export function getAfterFloats(num: number, precision = 2): string {
  return (num % 1).toFixed(precision).substr(2)
}

const setFn = function (
  el: HTMLElement,
  binding: any,
  vNode: any,
  prevNode: any,
) {
  if (binding.value && typeof binding.value === 'number') {
    const { noRounding } = binding.modifiers
    const value = currencyConvertHelper(binding.value, noRounding)

    if (value !== binding.value) {
      el.textContent = round(value)
    } else {
      el.textContent = noRounding
        ? binding.value.toString()
        : round(binding.value)
    }
  } else {
    localWarn('binded value is not a number !')
  }
}

export const vConvertToTargetCurrency = {
  async beforeMount(el: HTMLElement, binding: any, vNode: any, _oldVnode: any) {
    // FIXME: composables should be moved outside of the directive
    const currencyStore = useMiscCurrencyStore()
    const { initCurrency } = useCurrencySetter()
    if (!currencyStore.HAS_LOOK_UP_TABLE) await initCurrency()

    setFn(el, binding, vNode, _oldVnode)
  },
  updated(el: HTMLElement, binding: any, vNode: VNode, _oldVnode: VNode) {
    setFn(el, binding, vNode, _oldVnode)
  },
} satisfies Directive

function localWarn(message: string): void {
  console.warn(`[CONVERT TO TARGET CURRENCY] - ${message}`)
}

function round(value: number): string {
  return Math.round(value).toFixed(0)
}
